import * as React from "react"
import Spacer from "../components/spacer"
import Preorder from "../components/preorder"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Footer = ({ title, image }) => {
  var data  = useStaticQuery(
    graphql`
      query Footer {
        footer:datoCmsFooter {
          instagram
          facebook
          phone
          caption
        }
      }
    `
  )
  return (
  <div className='colophon'>
    <div className='p40'>
      <Spacer />
      <div className=''>
        <div className='max-500 ma white-newsletter'>
          <p className='m0 grey mb10 uppercase'>Stay in the loop</p>
          <Preorder />
        </div>
      </div>
      <div className='mt80'>
        <div className='max-600 ma text-center'>
          <div className='strong uppercase white-space'>{data.footer.caption}</div>
        </div>
        <div className='contact mt20 text-center uppercase'>
          {( data.footer.phone ? <p className='m0'>Phone <a className='link ul-link' href={data.footer.phone}>{data.footer.phone}</a></p>:'')}
          <p className='m0'>
            <a className='link ul-link' target='_blank' rel='noreferrer' href={data.footer.instagram}>Instagram</a> -  <a className='link ul-link' target='_blank' rel='noreferrer' href={data.footer.facebook}>Facebook</a>
          </p>
        </div>
      </div>
      <Spacer />
    </div>
  </div>
  )
}

export default Footer
