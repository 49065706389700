import * as React from "react"
import scrollTo from "../utils/scrollTo"
import Preorder from "../components/preorder"

const Introduction = ({content, link, button, buttonText, preorder, quantities, sample, sampleText}) => (
  <div className='introduction p40 m-pt0'> 
    <div className='w-70 m-100'>
      <div className='uppercase max-750 internal--content flex flex-col gap-15 mb40' dangerouslySetInnerHTML={{ __html:content }} />
      {( quantities.length > 0 ?
        <Quantities quantities={quantities} button={button} />:
        <>{(link ? <a className='button  m0' target='_blank' href={link}>{(button)}</a>:'')}</>
      )}
      {( sample ? 
        <div className='mt40'>
          <a className='button  uppercase m0' target='_blank' href={sample.url}>{(sampleText)}</a>
        </div>:''
      )}
      {(preorder ? 
        <div className='max-500 mt40 pt20'>
          <p className='strong uppercase'>{preorder}</p>
          <Preorder />
        </div>:''
      )}
    </div>
  </div>
)

export default Introduction

const Quantities = ({quantities, button}) => {
  const onClick = (e) => {
    var checkbox = e.target;
    var qty = checkbox.getAttribute('data-qty');
    document.querySelector('.checkbox.active').classList.remove('active');
    document.querySelector('.checkout--button.active')?.classList.remove('active');
    document.querySelector('.checkout--button[data-qty="'+qty+'"]')?.classList.add('active');
    checkbox.classList.add('active');
  }
  return (
    <div className='quantities'>
      <label className='small uppercase bold'>Quantity:</label>
      <div className='flex gap-10 mt10'>
        {quantities?.map((quantity, index) => { 
          return (
            <div data-qty={quantity.quantity} onClick={onClick} role='presentation' className={'checkbox '+(index===0?'active':'')}>{quantity.quantity}</div>
          )
        })}
      </div>
      <div className='buttons mt40'>
        {quantities?.map((quantity, index) => { 
          return (
            <a data-qty={quantity.quantity} className={'button checkout--button  m0 '+(index===0 ? 'active':'')} target='_blank' href={quantity.stripe}>{(button)}</a>
          )
        })}
      </div>
    </div>
  )
}