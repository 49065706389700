import * as React from "react"

const Gallery = ({image, title, gallery}) => {
  const onClick = (url) => {
    document.querySelector('.primary--image').setAttribute('src',url);
  }
  return (
  <div className='image--gallery p40 pt0'>
    <div className='flex m-wrap'>
      <div className='image w-100'>
        <div className='ratio-1-2 bg-grey pos-rel'>
          {( image ? <img className='primary--image bg-image' alt='Listing' src={image?.url} />:'')}
        </div>
      </div>
      <div className='gutter '>
        <div className='inner'>
          {gallery?.map((image, index) => { 
            return (
              <div className='image mb20'>
                <div className='ratio-1-2 bg-grey pos-rel pointer' role='presentation' onClick={() => onClick(image.url)}> 
                  {( image.url ? <img src={image?.url} className='bg-image' alt={'Gallery '+index+1} />:'')}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
  )
}

export default Gallery
