import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: ""

    }
    
  }
  componentDidMount() {
    document.querySelector("#preorder").addEventListener("submit", this.handleSubmit)
  }
  

  handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("preorder")
    let formData = new FormData(myForm)
    if(formData.get('Email')){
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        const form = document.querySelector("form")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("#preorder").addEventListener("submit", this.handleSubmit);
    }
  }

  render() {
    const formSubmitted = this.state.formSubmitted  
    if(!formSubmitted){
    return (
      <div className="form text-center mb40">
        <form method="POST" data-netlify="true" name="Preorder" netlify-honeypot='bot-field' id="preorder" className="max-450 ma">
            <input type='hidden' name='bot-field' />
            <input type="hidden" name="form-name" value="Preorder" />
            <div className='flex'>
              <input required={true} name="Email" type="text" className="p20 w-100 newsletter-input input" placeholder="Email Address" />
              <button type="submit" className="p20 mln1 input newsletter-button pointer">Submit</button> 
            </div>  
          </form>
      </div>
    )}else{
      return (
      <div className="form-section mt20 mb20" id="thank-you">
        <p className='m0'>Thank you for joining! We'll be in touch soon.</p>
      </div>
      )
    }
  }
}

export default EnquiryForm
