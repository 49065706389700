import * as React from "react"

const Content = ({content}) => { 
  const onClick = (index) => {
    var accordion = document.querySelector('.accordion[data-index="'+index+'"]');
    if(accordion.classList.contains('active')) {
      accordion.classList.remove('active');
      accordion.querySelector('.content').style.height = '0px';
    } else {
      accordion.classList.add('active');
      accordion.querySelector('.content').style.height = accordion.querySelector('.inner').offsetHeight+'px';
    }
  }
  return (
    <div className='content p40'>
      {content?.map((item, index) => { 
        return (
          <div data-index={index} key={index} className='accordion bb1 b-light' role='presentation' onClick={() => onClick(index)}>
            <div className='title pt20 pb20'>
              {item.title}
            </div>
            <div className='content'>
              <div className='inner white-space'>
                <div className='pb20'>
                  {item.content}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Content
