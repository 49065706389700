import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, className=''}) =>{
  const openMenu = () => {
    document.querySelector('.mobile--menu').classList.add('active');
  }

  const closeMenu = () => {
    document.querySelector('.mobile--menu').classList.remove('active');
  }
  return (
  <>
  <header className={'masthead '+className}>
    <div className='p20 ml20 mr20 flex align-center m-m0'>
      <Link className='link h2' to="/">
        Foster Burton
      </Link>
      <div className='menu mla aktiv m-hide'>
        <Link className='mr20 link' activeClassName='active' to='/'>Home</Link>
        <Link className='mr20 link' activeClassName='active' to='/about'>About</Link>
        <Link className='mr20 link' activeClassName='active' to='/listings'>Books</Link>
        <Link className='mr20 link' activeClassName='active' to='/blog'>Blog</Link>
      </div>
      <div className='mla m-show pointer' onClick={openMenu} role='presentation'>MENU</div>
    </div>
  </header>
  <div className='mobile--menu'>
    <div className='header flex align-center white flex w-100'>
      <p className='close-menu p20 mt10 m0 mla pointer' onClick={closeMenu} role='presentation'>CLOSE</p>
    </div>
    <div className='h-100a w-100 flex'>
      <div className='menu ma pb40 aktiv white text-center'>
        <Link className='h2 mb20 inline link' activeClassName='active' to='/about'>About</Link><br/>
        <Link className='h2 mb20 inline link' activeClassName='active' to='/listings'>Books</Link><br/>
        <Link className='h2 mb20 inline link' activeClassName='active' to='/blog'>Blog</Link><br/>
      </div>
    </div>
  </div>
  </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
 