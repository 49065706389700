import React from "react"
import { graphql } from 'gatsby'
import Seo from "../components/seo"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import Gallery from "../components/gallery"
import Content from "../components/content"
import TitleListing from "../components/titleListing"
import Introduction from "../components/introContent"

const BlogPost = ({data}) => {
  data = data.datoCmsListing
  console.log(data.quantities)
  return (
    <Layout header='dark' title="News & Updates">
      <Spacer className='half' />
      <div className='pth'>
        <TitleListing title={data.title} link={data.link} button={data.button} buttonText={data.listingStatus === 'For Sale' ? 'Purchase' : 'Enquire'} price={(data.listingStatus ==='Sold' ? data.price+' / SOLD' : data.price)}/>
      </div>
      <Gallery gallery={data.fullGallery} image={data.featureImage} title={data.title} />
      {( data.introduction ?
        <>
          <Spacer className='half'  />
          <Introduction content={data.introduction} link={data.link} button={data.button} preorder={data.preorderTitle} quantities={data.quantities} sample={data.samplePdfDownload} sampleText={data.sampleText} />
          <Spacer className='half'  />
        </> : ''
      )}
      {( data.moreContent.length ? 
        <>
          <Content content={data.moreContent} />
          <Spacer />
        </>:''
      )}
    
    </Layout> 
  )
}


export const Head = ({data}) => <Seo title={data.datoCmsListing.title} />

export default BlogPost

export const query = graphql`
    query Post($id :String!) {
      datoCmsListing(id:{eq:$id}){
        title
        price
        link 
        button
        introduction
        listingStatus
        featureImage {
          gatsbyImageData
          url
        }
        firstImage {
          gatsbyImageData
        }
        fullGallery {
          gatsbyImageData
          url
        }
        sampleText
        samplePdfDownload {
          url
        }
        quantities {
          quantity
          stripe
        }
        preorderTitle
        moreContent {
          title
          content
        }
      }
    }
`